var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: ["media-player-transcript", { showing: _vm.showing }],
      style: { background: _vm.$themeTokens.surface },
      attrs: {
        dir: _vm.languageDir,
        "aria-hidden": (!_vm.showing).toString(),
        "aria-label": _vm.coreString("transcript"),
      },
      on: {
        mouseenter: function ($event) {
          _vm.hovering = true
        },
        mouseleave: function ($event) {
          _vm.hovering = false
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.cues.length,
              expression: "!cues.length",
            },
          ],
          staticClass: "fill-space loading-space",
        },
        [
          _c("KCircularLoader", {
            staticClass: "loader",
            attrs: { delay: true },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cues.length,
              expression: "cues.length",
            },
          ],
          staticClass: "transcript-cap",
          style: _vm.capStyle,
        },
        [_vm._v("\n    " + _vm._s(_vm.$tr("transcriptBeginning")) + "\n  ")]
      ),
      _vm._l(_vm.cues, function (cue) {
        return [
          _c("TranscriptCue", {
            key: cue.id,
            ref: cue.id,
            refInFor: true,
            attrs: {
              cue: cue,
              active: _vm.activeCueIds.indexOf(cue.id) >= 0,
              mediaDuration: _vm.mediaDuration,
            },
            on: { seek: _vm.handleSeekEvent, goTo: _vm.handleGoTo },
          }),
        ]
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cues.length,
              expression: "cues.length",
            },
          ],
          staticClass: "transcript-cap",
          style: _vm.capStyle,
        },
        [_vm._v("\n    " + _vm._s(_vm.$tr("transcriptEnd")) + "\n  ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }