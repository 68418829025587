var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MediaPlayerFullscreen",
    {
      ref: "fullscreen",
      staticClass: "fill-space fullscreen-wrapper",
      style: {
        "border-color": _vm.$themeTokens.fineLine,
        padding: _vm.fullscreenWrapperPadding,
      },
      on: {
        changeFullscreen: function ($event) {
          _vm.isFullscreen = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "wrapper",
          class: [
            "wrapper",
            {
              "keyboard-modality": _vm.$inputModality === "keyboard",
              "video-loading": _vm.loading,
              "transcript-visible": _vm.transcriptVisible,
              "transcript-wrap": _vm.transcriptWrap,
            },
            _vm.$computedClass(_vm.progressStyle),
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "fill-space loading-space",
            },
            [
              _c("KCircularLoader", {
                staticClass: "loader",
                attrs: { delay: true },
              }),
            ],
            1
          ),
          _vm.isVideo
            ? _c(
                "video",
                {
                  ref: "player",
                  staticClass:
                    "custom-skin video-js vjs-big-play-centered vjs-show-big-play-button-on-pause",
                },
                [
                  _vm._l(_vm.videoSources, function (video) {
                    return [
                      _c("source", {
                        key: video.storage_url,
                        attrs: {
                          src: video.storage_url,
                          type: "video/" + video.extension,
                        },
                      }),
                    ]
                  }),
                  _vm._l(_vm.trackSources, function (track) {
                    return [
                      _c("track", {
                        key: track.storage_url,
                        attrs: {
                          kind: "captions",
                          src: track.storage_url,
                          srclang: track.lang.id,
                          label: track.lang.lang_name,
                          default: _vm.isDefaultTrack(track.lang.id),
                        },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _c(
                "audio",
                { ref: "player", staticClass: "custom-skin video-js" },
                [
                  _vm._l(_vm.audioSources, function (audio) {
                    return [
                      _c("source", {
                        key: audio.storage_url,
                        attrs: {
                          src: audio.storage_url,
                          type: _vm.audioSourceType(audio.extension),
                        },
                      }),
                    ]
                  }),
                  _vm._l(_vm.trackSources, function (track) {
                    return [
                      _c("track", {
                        key: track.storage_url,
                        attrs: {
                          kind: "captions",
                          src: track.storage_url,
                          srclang: track.lang.id,
                          label: track.lang.lang_name,
                          default: _vm.isDefaultTrack(track.lang.id),
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
          _vm.transcriptVisible
            ? _c("MediaPlayerTranscript", { ref: "transcript" })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }