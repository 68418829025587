var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.open,
          expression: "open",
        },
      ],
      staticClass: "vjs-menu",
      attrs: { "aria-hidden": (!_vm.open).toString() },
    },
    [
      _c("div", { staticClass: "vjs-menu-content" }, [
        _c(
          "ul",
          { ref: "contentEl", attrs: { role: "menu" } },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }