var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: [
        "transcript-cue",
        { active: _vm.active },
        _vm.$computedClass(_vm.style),
      ],
      attrs: {
        role: "button",
        tabindex: "0",
        title: _vm.$tr("title", { startTime: _vm.startTime }),
        "aria-current": _vm.active.toString(),
      },
      on: {
        click: _vm.triggerSeekEvent,
        keypress: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.triggerSeekEvent.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.triggerSeekEvent.apply(null, arguments)
          },
        ],
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "home", undefined, $event.key, undefined)
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.$emit("goTo", "beginning")
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "end", undefined, $event.key, undefined)
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.$emit("goTo", "end")
          },
        ],
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "transcript-cue-time",
          style: _vm.timeStyle,
          attrs: { "aria-label": _vm.$tr("timeLabel") },
        },
        [_vm._v(_vm._s(_vm.startTime))]
      ),
      _c(
        "span",
        {
          staticClass: "transcript-cue-text",
          style: _vm.textStyle,
          attrs: { "aria-label": _vm.$tr("textLabel") },
        },
        [
          _vm.speaker ? _c("strong", [_vm._v(_vm._s(_vm.speaker))]) : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }