var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "vjs-menu-item", attrs: { role: "menuitem" } },
    [
      _c("KCheckbox", {
        ref: "kCheckbox",
        attrs: {
          label: _vm.coreString("transcript"),
          checked: _vm.selected,
          role: "menuitem",
        },
        on: {
          change: _vm.toggleTranscript,
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              ) {
                return null
              }
              return _vm.toggleTranscript.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.toggleTranscript(), _vm.$emit("hide")
            },
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }