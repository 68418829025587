var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KIcon", {
    staticStyle: { top: "0", width: "24px", height: "24px" },
    attrs: { icon: "language", color: _vm.$themeTokens.textInverted },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }